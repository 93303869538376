// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-template-js": () => import("./../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-not-found-template-js": () => import("./../src/templates/not-found-template.js" /* webpackChunkName: "component---src-templates-not-found-template-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-patch-template-js": () => import("./../src/templates/patch-template.js" /* webpackChunkName: "component---src-templates-patch-template-js" */)
}

